import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.light,
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: theme.palette.primary.dark,
    transition: "color 0.5s ease",
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "rgba(255,255,255,0.5)",
    zIndex: 1,
    fontSize: 18,
  },
  circleDone: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.dark,
    transition: "color 0.5s ease",
  },
}));

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <div className={classes.circleDone}></div>
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const CustomStepper = (props) => {
  const { activeStep, steps } = props;

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={null}
      style={{ backgroundColor: "transparent", marginTop: "auto" }}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
