import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import ValueSelector from "../../../../components/ValueSelector/ValueSelector";

const StepEntregasDCEUFRJ = ({
  tipoEntrega,
  setTipoEntrega,
  delivery,
  setDelivery,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Selecione o método de entrega
          </FormLabel>
          <RadioGroup
            aria-label="delivery"
            name="delivery"
            value={tipoEntrega ?? ""}
            onChange={(e) => setTipoEntrega(e.target.value)}
          >
            <FormControlLabel
              value="entrega"
              control={<Radio />}
              label="Entregar para meu endereço"
            />
            <FormControlLabel
              value="retirada"
              control={<Radio />}
              label="Retirada na sede"
            />
          </RadioGroup>
        </FormControl>
      </div>
      {tipoEntrega === "retirada" && (
        <div>
          <div style={{ marginTop: "10px" }}>
            <ValueSelector
              label="Selecione o local de retirada"
              choices={[
                "Fundão - Centro Acadêmico de Engenharia",
                "Centro - CAMMA",
                "Macaé - Polo",
                "Caxias",
                "PV - Xérox do DCE (Raphael)",
              ]}
              name={"delivery"}
              onChange={(e) => setDelivery(e.target.value)}
              value={delivery}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default StepEntregasDCEUFRJ;
