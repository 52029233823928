import { CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import axios from "axios";
import { useField } from "formik";
import React, { useEffect, useState } from "react";

export default function AutoCompleteInstitution(props) {
  const { instSearchParams, ensinoSelected, value } = props;
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState("Digite pelo menos 3 caracteres");
  const fieldProps = useField(props.name);
  const [, meta, ] = fieldProps;
  const showError = meta.touched && !!meta.error;

  useEffect(() => {
    let ensino = {};
    ensino.ensino = [ensinoSelected];

    const otherOptions = { ...instSearchParams, ...ensino };
    async function fetch() {
      return new Promise((resolve) => {
        axios
          .post("https://carteira-entidade-search.herokuapp.com/ent_src", {
            text: inputValue,
            ...otherOptions,
          })
          .then(function (response) {
            setOptions([...response.data]);
            if (response.data.length === 0) {
              setOptions([{ nome:inputValue, opt:'not_found' }]);
            }
          })
          .catch(() => {
            setOptions([{ nome: inputValue }]);
          });
        resolve();
      });
    }
    const delayDebounceFn = setTimeout(async () => {
      setOptions([]);
      if (inputValue.length < 3) {
        setLabel("Digite pelo menos 3 caracteres...");
        return;
      }

      if (value?.nome === inputValue) {
        return;
      }
      setLabel("Carregando...");
      setLoading(true);

      await fetch();
      setLoading(false);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, ensinoSelected, instSearchParams, value]);
  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      onChange={props.onChange}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, _value) => option.nome === _value.nome}
      getOptionLabel={(option) => `${(option?.sigla && option?.sigla + ' - ') || ''}${option.nome}`}
      options={options}
      loading={loading}
      value={value}
      
      autoHighlight
      autoSelect

      inputValue={inputValue}
      noOptionsText={label}
      loadingText="Carregando..."
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Escola"
          name={props.name}
          variant="outlined"
          error={showError}
          helperText={meta.error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.nome_search, inputValue);
        const parts = parse(option.nome_search, matches);

        if (option?.opt === 'not_found') {
          return <li {...props} key={0}>
            {option.nome}
          </li>
        }
        return (
          <li {...props} key={option.id}>
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
}
