import React, { useState } from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import CustomButton from "../../../components/CustomButton/CustomButton";
import SuccessAnimation from "../../../components/SuccessAnimation/SuccessAnimation";
import ReCAPTCHA from "react-google-recaptcha";

import "../FormEnvio.css";

const CAPTCHA_KEY = "6Leux8AbAAAAADK1NGH7sdNfXF2Tm5n8-rYr9hcr";

const StepConfirm = (props) => {
  const createCarteira = props.createCarteira;
  const buttons = props.buttons;
  const data = props.data;
  const [fetching, setFetching] = useState(false);
  const [response, ] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [captcha, setCaptcha] = useState();

  const handleChange = (value) => {
    setCaptcha(value);
  };
  const onClick = () => {
    setFetching(true);
    createCarteira(data)
      .then((_response) => {
        setFetching(false);
        setSuccess(true);
      })
      .catch((_error) => {
        setError(true);
        setFetching(false);
      });
  };

  const ErrorScreen = () => {
    return (
      <main>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <img src="error.svg" alt="Erro" width="300" height="300" />
          <h1>Ocorreu um erro na sua solicitação!</h1>
        </Box>
      </main>
    );
  };

  const SuccessScreen = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <div>
          <SuccessAnimation />
          <Typography color="textPrimary" variant="h4" align="center">
            Sua solicitação foi enviada com sucesso!
          </Typography>
        </div>
      </Box>
    );
  };

  const onReqScreen = () => {
    return (
      <>
        <main>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            {!!fetching && <CircularProgress size={200} />}
            {response}
            {!fetching && (
              <>
                <CustomButton
                  onClick={onClick}
                  title="Solicitar Carteira"
                  color={props.entidade.color}
                  disabled={!captcha}
                />
                <ReCAPTCHA sitekey={CAPTCHA_KEY} onChange={handleChange} />
              </>
            )}
          </Box>
        </main>
        <footer>{buttons()}</footer>
      </>
    );
  };

  return (
    <>
      <header>
        <div>
          <Typography color="textPrimary" variant="h2">
            Tá quase tudo pronto
          </Typography>
          <Typography color="textSecondary" variant="h5">
            Confirme seus dados e já pode solicitar sua carteirinha :)
          </Typography>
        </div>
      </header>
      {!error && !success && onReqScreen()}
      {!error && success && SuccessScreen()}
      {error && !success && ErrorScreen()}
    </>
  );
};

export default StepConfirm;
