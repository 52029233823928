import axios from "axios";

const ApiURL = process.env.REACT_APP_API || "http://0.0.0.0:5000";
// const ApiURL ='http://0.0.0.0:5000';

const ApiService = {
  async create_carteirinha(data, entidade_url) {
    const url = ApiURL + `/carteira/${entidade_url}`;
    let formData = new FormData();
    for (var key of Object.keys(data)) {
      if (
        key !== "photo" &&
        key !== "comprovantePagamento" &&
        key !== "comprovanteMatricula" &&
        key !== "rgBackSide" &&
        key !== "rgFrontSide"
      ) {
        formData.append(key, data[key]);
      }
    }
    const localFile = await fetch(data.photo);
    const fileBlob = await localFile.blob();

    formData.append("photo", fileBlob);
    formData.append("comprovantePagamento", data.comprovantePagamento);
    formData.append("comprovanteMatricula", data.comprovanteMatricula);
    formData.append("rgFrontSide", data.rgFrontSide);
    formData.append("rgBackSide", data.rgBackSide);

    return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  async get_carteirinha_validate(entidade_url, id, token) {
    const url = ApiURL + `/carteira/${entidade_url}/${id}/${token}`;
    return axios.get(url);
  },

  async ping() {
    const url = ApiURL + `/ping`;
    return axios.get(url);
  },
};

export default ApiService;
