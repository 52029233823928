import { Container, Fab } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { useState } from "react";
import "../../pages/FormEnvio/FormEnvio.css";

const FileInput = ({
  id,
  value,
  setValue,
  name,
  accept,
  onChange,
  color,
  Icon,
  description,
}) => {
  const [error, setError] = useState("");

  const fileInputChange = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      setError("Arquivo muito grande, tente outro ou comprima o arquivo.");
      return;
    }

    setError("");

    onChange(event);
  };

  return (
    <div className="comprovante-button">
      <Container display="flex" fullWidth m={12}>
        <label htmlFor={id}>
          <input
            style={{ display: "none" }}
            id={id}
            name={name}
            accept={accept}
            type="file"
            onChange={fileInputChange}
          />
          <Fab
            color={color}
            size="large"
            component="span"
            aria-label="add"
            variant="extended"
            disabled={value}
            style={{ width: "100%" }}
          >
            {Icon}
            {description}
          </Fab>
        </label>
        {!!value && (
          <Box display="flex" justifyContent="flex-end">
            <Typography color="textSecondary" gutterBottom variant="body2">
              {value.name}
            </Typography>
            <HighlightOffOutlinedIcon onClick={() => setValue(null)} />
          </Box>
        )}
        <Typography style={{paddingTop: "5px"}} color="textSecondary" variant="body2">
          {" "}
          Tamanho Máximo 5 MB
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
      </Container>
    </div>
  );
};

export default FileInput;
