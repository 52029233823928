import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import maskFunctions from "../../../utils/maskFunctions";

import "../FormEnvio.css";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const StepDadosEstudante = (props) => {
  useStyles();

  const nextStep = props.nextStep;
  const buttons = props.buttons;
  const setData = props.setData;
  const data = props.data;
  return (
    <Formik
      initialValues={{
        nome: "",
        rg: "",
        cpf: "",
        dataNasc: "",
        telefone: "",
        email: "",
        ...data,
      }}
      validationSchema={Yup.object().shape({
        nome: Yup.string()
        .max(30, 'O nome deve ter no máximo 30 caracteres')
        .required("Digite seu nome completo"),
        rg: Yup.string().required("Digite seu RG"),
        cpf: Yup.string().min(14, "CPF Inválido").required("Digite seu CPF"),
        dataNasc: Yup.string()
          .min(10, "")
          .required("Digite sua data de nascimento")
          .matches(/^[0-9]{2}[/]{1}[0-9]{2}[/]{1}[0-9]{4}$/, ""),
        telefone: Yup.string().min(9, "").required("Digite seu telefone"),
        email: Yup.string()
          .email("Digite um email válido")
          .required("Digite seu email"),
      })}
      onSubmit={(values) => {
        setData(values);
        nextStep();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <>
          <header>
            <div>
              <Typography color="textPrimary" variant="h2">
                Dados Pessoais
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Insira seus dados pessoais :)
              </Typography>
            </div>
          </header>
          <main>
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.nome && errors.nome)}
                fullWidth
                helperText={touched.nome && errors.nome}
                label="Nome"
                margin="normal"
                name="nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nome}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.rg && errors.rg)}
                fullWidth
                helperText={touched.rg && errors.rg}
                label="RG"
                margin="normal"
                name="rg"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.rg}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.cpf && errors.cpf)}
                fullWidth
                helperText={touched.cpf && errors.cpf}
                label="CPF"
                margin="normal"
                name="cpf"
                onBlur={handleBlur}
                onChange={(event) => {
                  maskFunctions.applyPattern(event, handleChange, "cpf");
                }}
                value={values.cpf}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.dataNasc && errors.dataNasc)}
                fullWidth
                helperText={touched.dataNasc && errors.dataNasc}
                label="Data de Nascimento"
                margin="normal"
                name="dataNasc"
                onBlur={handleBlur}
                onChange={(event) => {
                  maskFunctions.applyPattern(event, handleChange, "date");
                }}
                value={values.dataNasc}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.telefone && errors.telefone)}
                fullWidth
                helperText={touched.telefone && errors.telefone}
                label="Celular"
                margin="normal"
                name="telefone"
                onBlur={handleBlur}
                onChange={(event) => {
                  maskFunctions.phonePattern(event, handleChange);
                }}
                value={values.telefone}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                variant="outlined"
              />
            </form>
          </main>
          <footer>{buttons(handleSubmit)}</footer>
        </>
      )}
    </Formik>
  );
};

export default StepDadosEstudante;
