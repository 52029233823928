import VMasker from "vanilla-masker";

const patterns = {
  cpf: "999.999.999-99",
  cep: "99999-999",
  date: "99/99/9999",
};

const telMask = ["(99) 9999-99999", "(99) 99999-9999"];

const maskFunctions = {
  applyPattern(event, handleChange, pattern) {
    let patt = "";
    if (pattern in patterns) {
      patt = patterns[pattern];
    }
    const value = VMasker.toPattern(event.target.value, patt);
    event.target.value = value;
    handleChange(event);
  },
  phonePattern(event, handleChange) {
    var c = event.target;
    var v = c.value.replace(/\D/g, "");
    var m = c.value.length > 14 ? 1 : 0;
    event.target.value = VMasker.toPattern(v, telMask[m]);
    handleChange(event);
  },
};

export default maskFunctions;
