import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Box,
  TextField,
  Typography,
  InputLabel,
  FormControl,
} from "@material-ui/core";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "../FormEnvio.css";
import AutoCompleteInstitution from "../../../components/AutoCompleteInstitution/AutoCompleteInstitution";

const StepDadosCurso = (props) => {
  const ensino = props.entidade?.ensino || [];
  const cursos = props.entidade?.cursos || null;
  const nextStep = props.nextStep;
  const buttons = props.buttons;
  const setData = props.setData;
  const hasInstitution = props.entidade?.instituicao || null;
  const instSearchParams = props.entidade?.instSearchParams;
  const data = props.data;

  const cursoInput = (touched, errors, handleBlur, handleChange, values) => {
    if (values?.ensino === 'Médio' || values?.ensino === 'Fundamental') {
      return;
    }

    if (!cursos) {
      return (
        <TextField
          error={Boolean(touched.curso && errors.curso)}
          fullWidth
          helperText={touched.curso && errors.curso}
          label="Curso"
          margin="normal"
          name="curso"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.curso}
          variant="outlined"
        />
      );
    }

    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Curso</InputLabel>
        <Select
          value={values.curso}
          helperText={touched.curso && errors.curso}
          onChange={handleChange}
          name="curso"
          fullWidth
          label="Curso"
        >
          {cursos.map((item) => {
            return <MenuItem value={item}>{item}</MenuItem>;
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <Formik
      initialValues={{
        hasInstitution: Boolean(hasInstitution),
        ensino: ensino.length > 0 ? ensino[0] : "",
        curso: "",
        matricula: "",
        instituicao: "",
        instituicaoManual: false,
        instituicaoObject: null,
        ...data,
      }}
      validationSchema={Yup.object().shape({
        hasInstitution: Yup.boolean(),
        instituicaoManual: Yup.boolean(),
        ensino: Yup.string().max(255).required(""),
        curso: Yup.string("Digite seu curso")
          .max(38, 'O nome do curso deve ter no máximo 38 caracteres'),
        matricula: Yup.string().required("Digite sua Matricula"),
        instituicao: Yup.string().when("hasInstitution", {
          is: false,
          then: () => 
            Yup.string()
            .when("instituicaoManual", {
              is: true,
              then: Yup.string().max(10, 'O nome da instituição deve ter no máximo 10 caracteres (Abrevie se necessário)'),
              otherwise: Yup.string()
            })
            .required("Você precisa escolher sua instituição de ensino"),
        }),
      })}
      onSubmit={(values) => {
        if (values?.instituicao === "") {
          delete values.instituicao;
        }
        delete values?.hasInstitution;
        setData(values);
        console.log(values);
        nextStep();
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values,
      }) => (
        <>
          <header>
            <div>
              <Typography color="textPrimary" variant="h2">
                Dados do seu Curso
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Insira seus dados do seu curso
              </Typography>
            </div>
          </header>
          <main>
            <form onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.matricula && errors.matricula)}
                fullWidth
                helperText={touched.matricula && errors.matricula}
                label="Matricula"
                margin="normal"
                name="matricula"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.matricula}
                variant="outlined"
              />
              <Box fullWidth mb={3}>
                <FormControl variant="outlined" fullWidth>
                  {cursoInput(
                    touched,
                    errors,
                    handleBlur,
                    handleChange,
                    values
                  )}
                </FormControl>
              </Box>
              <Box fullWidth mb={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Ensino</InputLabel>
                  <Select
                    value={values.ensino}
                    helperText={touched.ensino && errors.ensino}
                    onChange={handleChange}
                    name="ensino"
                    fullWidth
                    label="Ensino"
                  >
                    {ensino.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box fullWidth mb={3}>
              </Box>
              {!hasInstitution && (
                <Box fullWidth mb={3}>
                  <FormControl variant="outlined" fullWidth>
                    <AutoCompleteInstitution
                      instSearchParams={instSearchParams}
                      ensinoSelected={values?.ensino}
                      onChange={(_e, value) => {
                        setFieldValue(
                          "instituicao",
                          (value !== null && 
                            (
                            (value.nome.length > 9 && value.sigla) 
                            || value.nome_resumido 
                            || value.nome
                            )) || ""
                        );
                        if(!value || value.opt === 'not_found') {
                          setFieldValue("instituicaoManual", true);
                        } else {
                          setFieldValue("instituicaoManual", false);
                        }
                        setFieldValue("instituicaoObject", value)
                      }}
                      value={values?.instituicaoObject}
                      name="instituicao"
                      error={Boolean(touched.instituicao && errors.instituicao)}
                      helperText={touched.instituicao && errors.instituicao}
                    />
                  </FormControl>
                </Box>
              )}
            </form>
          </main>
          <footer> {buttons(handleSubmit)}</footer>
        </>
      )}
    </Formik>
  );
};

export default StepDadosCurso;
