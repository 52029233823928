import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import ValueSelector from "../../../../components/ValueSelector/ValueSelector";

const StepEntregasUEP = ({
  tipoEntrega,
  setTipoEntrega,
  delivery,
  setDelivery,
}) => {

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Selecione o método de entrega
          </FormLabel>
          <RadioGroup
            aria-label="delivery"
            name="delivery"
            value={tipoEntrega ?? ""}
            onChange={(e) => setTipoEntrega(e.target.value)}
          >
            <FormControlLabel
              value="entrega"
              control={<Radio />}
              label="Entregar para meu endereço"
            />
            <FormControlLabel
              value="retirada"
              control={<Radio />}
              label="Retirada na sede"
            />
          </RadioGroup>
        </FormControl>
      </div>
      {tipoEntrega === "entrega" && (
        <div>
          <Typography color="textPrimary" variant="body" fontSize="18px">
            Lembrando que você optou pela entrega pelos Correios, sendo assim o
            valor é de R$ 22,00 + R$ 8,00, totalizando
            <strong> R$ 30,00</strong>. Escolha entre as Formas de pagamento
            disponíveis:
          </Typography>
          <div style={{ marginTop: "10px" }}>
            <Typography color="textPrimary" component="li" variant="body">
              <strong>Depósito/Transferência</strong> - Banco do Brasil - União
              dos Estudantes de Pernambuco AG: 0007-8 C/C: 54221-0 CNPJ
              09.039.082/0001-52
            </Typography>
            <Typography color="textPrimary" component="li" variant="body">
              <strong>Boleto/Cartão</strong> - Acesse:
              <a
                href="https://pag.ae/7-4ZCMg2H"
                target="_blank"
                rel="noreferrer"
              >
                https://pag.ae/7-4ZCMg2H
              </a>
            </Typography>
            <Typography color="textPrimary" component="li" variant="body">
              <strong>Chave Pix</strong> - CNPJ: 09.039.082/0001-52
            </Typography>
          </div>
        </div>
      )}
      {tipoEntrega === "retirada" && (
        <div>
          <Typography color="textPrimary" variant="body" fontSize="18px">
            Lembrando que você optou por buscar a carteira na Sede, sendo assim
            o valor é de <strong>R$ 22,00</strong>. FUNCIONAMENTO DA SEDE: 9h às
            16h (segunda à sexta). Escolha entre as Formas de pagamento
            disponíveis:
          </Typography>
          <div style={{ marginTop: "10px" }}>
            <ValueSelector
              label="Selecione o local de retirada"
              choices={{
                UEP: "Empresarial Ninfas, Sala 203, Rua das Ninfas, 262, Soledade, Recife-PE",
                UESC: "Rua Bahia, 168 - Divinópolis, Caruaru - PE",
                UESP: "Rua das Laranjeiras, 234, Centro, Petrolina-PE",
              }}
              name={"delivery"}
              onChange={(e) => setDelivery(e.target.value)}
              value={delivery}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <Typography color="textPrimary" component="li" variant="body">
              <strong>Depósito/Transferência</strong> - Banco do Brasil - União
              dos Estudantes de Pernambuco AG: 0007-8 C/C: 54221-0 CNPJ
              09.039.082/0001-52
            </Typography>
            <Typography color="textPrimary" component="li" variant="body">
              <strong>Boleto/Cartão</strong> - Acesse:
              <a
                href="https://pag.ae/7-4ZxSUYH"
                target="_blank"
                rel="noreferrer"
              >
                https://pag.ae/7-4ZxSUYH
              </a>
            </Typography>
            <Typography color="textPrimary" component="li" variant="body">
              <strong>Chave Pix</strong> - CNPJ: 09.039.082/0001-52
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepEntregasUEP;
