import axios from "axios";

const ApiURL =
  process.env.REACT_APP_API_ENTIDADES ||
  "https://carteira-entidade-search.herokuapp.com";

const ApiSearchService = {
  async ping() {
    const url = ApiURL + `/`;
    return axios.get(url);
  },
};

export default ApiSearchService;
