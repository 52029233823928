import React from "react";
import { Typography } from "@material-ui/core";
import CustomButton from "../../../components/CustomButton/CustomButton";
import "../FormEnvio.css";
const StepWelcome = (props) => {
  const nextStep = props.nextStep;
  const setData = props.setData;

  const entidade = props.entidade;

  const IsDceUfrj = entidade.url === "dce_ufrj";

  return (
    <>
      <main>
        <Typography color="textPrimary" align="center" variant="h2">
          Sistemas de Carteirinhas do {entidade.sigla}
        </Typography>
        <div className="v-centering" style={{ paddingTop: "20px" }}>
          <Typography color="textSecondary" variant="h4">
            Você vai precisar de:
          </Typography>
          <Typography color="textSecondary" variant="h5">
            <ul className="lista">
              <li>RG frente e verso</li>
              <li>Comprovante de Matricula</li>
              <li>Comprovante de Pagamento da Carterinha</li>
              <li>Foto</li>
            </ul>
          </Typography>
        </div>
        {IsDceUfrj && (
          <Typography
            color="textPrimary"
            variant="h5"
            align="center"
            style={{ marginTop: "20px" }}
          >
            ATENÇÃO, NÃO FAÇA SE VOCÊ FOR DOS SEGUINTES CURSOS: para os
            estudantes do IH, Escola Politécnica, Escola de Química, Escola de
            Comunicação e Biologia Macaé, o DCE não emite carteirinha, faça
            direto no seu Centro Acadêmico que estamos com parceria e garanta os
            mesmos direitos, pelo mesmo preço!
          </Typography>
        )}
      </main>
      <footer className="centering">
        <CustomButton
          title="Vamos começar?"
          onClick={() => {
            nextStep();
            setData({});
          }}
        />
      </footer>
    </>
  );
};

export default StepWelcome;
