import React from "react";
import "./Home.css";
export default function Home() {
  return (
    <div className="home">
      <div>
        <img src={process.env.PUBLIC_URL + "/logo512.png"} alt="" />
      </div>
    </div>
  );
}
