import React from "react";
import "./CustomButton.css";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const ColorButton = withStyles((theme) => ({
  root: {
    maxWidth: "300px",
    maxHeight: "300px",
    minWidth: "90%",
    minHeight: "30px",
    fontSize: "30px",
    marginBottom: "20px",
    borderRadius: "30px",
    textTransform: "none",
    color: "black",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))(Button);

export default function CustomButton(props) {
  return (
    <ColorButton variant="contained" color="primary" {...props}>
      {props.title}
    </ColorButton>
  );
}
