import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import CustomStepper from "../../components/CustomStepper/CustomStepper";
import Entidades from "../../entidades.json";
import ApiSearchService from "../../services/ApiSearchService";
import ApiService from "../../services/ApiService";
import "./FormEnvio.css";
import StepComprovantes from "./steps/StepComprovantes";
import StepConfirm from "./steps/StepConfirm";
import StepDadosCurso from "./steps/StepDadosCurso";
import StepDadosEstudante from "./steps/StepDadosEstudante";
import StepEndereco from "./steps/StepEndereco";
import StepFoto from "./steps/StepFoto";
import StepWelcome from "./steps/StepWelcome";
function FormEnvio(props) {
  const [data, setData] = useState({});
  const [step, setStep] = useState(0);
  const [entidade, setEntidade] = useState(null);
  const [fade, setFade] = useState(true);
  const maxStep = 6;

  const steps = [
    "Dados pessoais",
    "Dados do seu curso",
    "Endereço",
    "Upload Foto",
    "Upload Comprovante",
    "Confirme seus dados",
  ];

  const createCarteira = () => {
    return ApiService.create_carteirinha(data, entidade.url);
  };
  const updateData = (v) => {
    setData({ ...data, ...v });
  };
  const pages = () => {
    if (step === 0)
      return (
        <StepWelcome
          setData={setData}
          buttons={previousAndNextButtons}
          nextStep={nextStep}
          entidade={entidade}
        />
      );
    if (step === 1)
      return (
        <StepDadosEstudante
          data={data}
          setData={updateData}
          buttons={previousAndNextButtons}
          nextStep={nextStep}
        />
      );
    if (step === 2)
      return (
        <StepDadosCurso
          data={data}
          setData={updateData}
          buttons={previousAndNextButtons}
          nextStep={nextStep}
          entidade={entidade}
        />
      );
    if (step === 3)
      return (
        <StepComprovantes
          data={data}
          setData={updateData}
          buttons={previousAndNextButtons}
          nextStep={nextStep}
          entidade={entidade}
        />
      );
    if (step === 4)
      return (
        <StepEndereco
          data={data}
          setData={updateData}
          buttons={previousAndNextButtons}
          nextStep={nextStep}
        />
      );
    if (step === 5)
      return (
        <StepFoto
          data={data}
          setData={updateData}
          buttons={previousAndNextButtons}
          nextStep={nextStep}
        />
      );
    if (step === 6)
      return (
        <StepConfirm
          data={data}
          createCarteira={createCarteira}
          buttons={previousAndNextButtons}
          entidade={entidade}
        />
      );
  };
  const nextStep = () => {
    if (step <= maxStep) {
      setFade(false);
      window.setTimeout(setFade, 100, true);
      window.setTimeout(setStep, 100, step + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setFade(false);
      window.setTimeout(setFade, 100, true);
      window.setTimeout(setStep, 100, step - 1);
    }
  };

  const previousAndNextButtons = (handleSubmit) => {
    var visible = step === 6 ? "hidden" : "visible";
    return (
      <div>
        <div>
          <Button
            variant="outlined"
            disableElevation
            onClick={() => prevStep()}
            disable={step === 0}
          >
            Anterior
          </Button>
        </div>
        <Box visibility={visible}>
          <Button
            variant="contained"
            disableElevation
            size="large"
            color="primary"
            onClick={() => handleSubmit()}
          >
            Próximo
          </Button>
        </Box>
      </div>
    );
  };

  useEffect(() => {
    const verifyEntidade = () => {
      let entidadeData = Entidades[props.id];

      if (entidadeData?.enable === false) return;
      entidadeData.theme = createMuiTheme({
        palette: { primary: { main: entidadeData.color || '#818cf8' } },
      });

      const backgroundStyle = {
        background: entidadeData.background,
      };

      entidadeData.backgroundStyle = backgroundStyle;
      setEntidade(entidadeData);
    };

    ApiService.ping();
    ApiSearchService.ping();
    verifyEntidade();
  }, [props]);

  return (
    <div className="background" style={entidade?.backgroundStyle}>
      <ThemeProvider theme={entidade?.theme}>
        <div class="container-p">
          <Animated
            className="pages"
            animationIn="fadeIn"
            animationOut="fadeOut"
            isVisible={fade}
            animationInDuration={100}
            animationOutDuration={100}
          >
            <div class="box">{entidade && pages()}</div>
          </Animated>
          <div className="footer">
            {entidade && <CustomStepper activeStep={step} steps={steps} />}
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default FormEnvio;
