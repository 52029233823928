import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import FormEnvio from "./pages/FormEnvio/FormEnvio";
import Validador from "./pages/Validador/Validador";
import Home from "./pages/Home/Home";

import Entidades from "./entidades.json";

function App() {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route exact path="/val/:sigla/:id/:token" component={Validador} />
      {Entidades.map((item, id) => {
        return (
          <Route
            key={id}
            exact
            path={`/${item.url}`}
            render={() => <FormEnvio entidade={`${item.url}`} id={id} />}
          />
        );
      })}
      <Route
        exact
        path={"/caeng_daeq"}
        render={() => <FormEnvio entidade={"caeng"} id={1} />}
      />{" "}
    </Router>
  );
}

export default App;
