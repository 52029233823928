import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import "./Validador.css";
import ApiService from "../../services/ApiService";
import { useParams } from "react-router-dom";

function Validador() {
  const [estudante, setEstudante] = useState({});
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);

  let { sigla, id, token } = useParams();

  useEffect(() => {
    setFetching(true);
    ApiService.get_carteirinha_validate(sigla, id, token)
      .then((response) => {
        setEstudante(response.data);
        setFetching(false);
      })
      .catch(() => {
        setError(true);
        setFetching(false);
      });
  }, [id, sigla, token]);

  const fetchingDiv = () => {
    return (
      fetching && (
        <div className="circular-box">
          <CircularProgress size={250} />
        </div>
      )
    );
  };

  const errorDiv = () => {
    return (
      error && (
        <div className="invalid-box">
          <h2>Carteira não encontrada!</h2>
        </div>
      )
    );
  };

  const studentFields = () => {
    return (
      estudante &&
      !error && (
        <>
          <div className="valid-box">
            <h3>Documento válido!</h3>
            <span>
              Documento padronizado nacionalmente conforme a lei 12.933/2013.
              Válido em todo território nacional até março do ano seguinte.
            </span>
          </div>

          <div className="img-container">
            <img src={estudante.photo_access} alt="Foto do estudante"></img>
          </div>

          <h4>Nome</h4>
          <span>{estudante.nome} </span>

          <h4>Instituição</h4>
          <span>{estudante.instituicao} </span>

          <h4>Curso</h4>
          <span>{estudante.curso} </span>

          <h4>Documento de identificação</h4>
          <span>{estudante.rg} </span>

          <h4>Emissor</h4>
          <span>{estudante.emissor}</span>
        </>
      )
    );
  };

  return (
    <div className="container">
      <div className="navbar">
        {" "}
        <h2> Meia Entrada Estudantil</h2>
      </div>
      <div className="center-box">
        {fetchingDiv()}
        {studentFields()}
        {errorDiv()}
      </div>
    </div>
  );
}

export default Validador;
